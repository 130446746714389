<template>
  <v-row class="fill-height ma-0" justify="center" align="center">
    <v-card max-width="400px" width="100%" class="py-4">
      <v-row no-gutters justify="center" class="title primary--text font-weight-bold">
        Prijavi se
      </v-row>
      <v-card-text>
        <v-text-field
          prepend-inner-icon="mdi-email"
          label="Email"
          filled
          v-model="email"
        ></v-text-field>
        <v-text-field
          prepend-inner-icon="mdi-lock"
          label="Sifra"
          filled
          type="password"
          v-model="password"
        ></v-text-field>
        <div class="text-center">
          <router-link :to="{name: 'ForgotPassword'}">Zaboravili ste sifru?</router-link>
        </div>
      </v-card-text>
      <v-card-actions class="mx-2">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="login"
          class="px-5 py-2"
        >
          Prijavi se
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <span class="pr-1">Nemate nalog?</span>
          <a @click="$emit('register')">Registruj se</a>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="snackbar.state"
      :timeout="3000"
      top
    >
      <template v-for="msg in snackbar.text">
        {{ msg.message }}
      </template>

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbar.color"
          text
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-row>
</template>

<script>
  export default {
    layout: 'blank',
    data() {
      return {
        email: '',
        password: '',
        snackbar: {
          state: false,
          text: '',
          color: ''
        }
      }
    },
    methods: {
      login() {
        this.$store.dispatch('LOGIN_USER', {
          email: this.email,
          password: this.password
        }).then(res => {
          this.$router.push({path: '/'})
        }).catch(err => {
          this.snackbar.state = true
          this.snackbar.text = err.response
          this.snackbar.color = 'error'
        })
      }
    }
  }
</script>
