<template>
  <div style="display: contents;">
    <v-calendar
      ref="calendar"
      v-model="focus"
      color="primary"
      :events="events2"
      :type="type"
      @change="updateRange"
      @click:event="showEvent"
    >
      <template v-slot:event="{ event }">

        <v-row justify="space-around" no-gutters>
          <div>{{event.name}}</div>
          <div>({{event.free}})</div>
        </v-row>

      </template>
    </v-calendar>

    <div v-if="slots" style="display:flex; width: 100%; justify-content: center; height: 48px" class="py-2">
      <template v-for="(item, i) in slots.data">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="item.selected ? 'slots3' : item.state ? 'slots' : 'slots2'"
              v-bind="attrs"
              v-on="on"
              @click="select(item)"
            >
              <div
                v-if="(parseFloat(moment.utc(item.from).format('HH:mm').toString().replace(':', '.')))%1 == 0"
                style="position:absolute; top: 40px; font-size: 10px"
              >
                {{moment.utc(item.from).format('HH:mm')}}
              </div>
            </div>
          </template>
          <span>{{moment.utc(item.from).format('HH:mm')}} - {{moment.utc(item.to).format('HH:mm')}}</span>
        </v-tooltip>


      </template>

    </div>
  </div>
</template>

<script>

import moment from "moment";
import axios from "../plugins/axios";

export default {
  props: {
    filters: {type: Object}
  },
  data() {
    return {
      moment: moment,
      focus: '',
      events2: [],
      type: 'month',
      slots: null
    }
  },
  methods: {
    updateRange ({ start, end }) {

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)

      this.filters.date = moment(min).format('YYYY-MM-DD')
      let params = new URLSearchParams(this.filters)

      axios.get('/lessons?' + params.toString()).then(res => {
        this.events2 = res.data
      })
    },
    showEvent(e) {
      let params = new URLSearchParams(this.filters)
      axios.get('/lessons2/'+ e.event.id +'?' + params.toString())
        .then(res => {
          this.slots = res.data
          this.events2.forEach(event => event.color = '#2196F3')
          e.event.color = '#426b8d'
          this.$emit('slots', this.slots)
        })
    },
    select(item) {
      this.slots.data.map(i => {
        if(i.id != item.id) i.selected = false
        return i
      });

      item.selected = !item.selected
    },
    resetSlot() {
      this.slots = null
    }
  }
}

</script>

<style>
.slots {
  height: 30px;
  width: 15px;
  margin-left: 2px;
  margin-right: 2px;
  background: #8fe286;
  position: relative;
}
.slots:hover {
  background: #04BB02;
}

.slots2 {
  height: 30px;
  width: 15px;
  margin-left: 2px;
  margin-right: 2px;
  background: #e6e9ef;
  position: relative;
}
.slots2:hover {
  background: #E42806;
}

.slots3 {
  height: 35px;
  width: 14px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: -2px;
  background: #2196f3;
  position: relative;
}

</style>
