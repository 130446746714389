<template>
  <layout-home>
    <template v-slot:main>

      <v-toolbar
        flat
        class="mb-2"
        color="white"
        elevation="2"
      >
        <v-btn
          outlined
          class="mr-4"
          color="grey darken-2"
          @click="setToday"
          :disabled="events.some(i => i.hasOwnProperty('saved') && !i.saved)"
        >
          Today
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="prev"
          :disabled="events.some(i => !i.saved)"
        >
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="next"
          :disabled="events.some(i => !i.saved)"
        >
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div v-if="events.some(i => !i.saved)">
          <v-btn color="error" class="mx-2" @click="save">SAVE</v-btn>
        </div>
        <v-menu
          bottom
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
              :disabled="events.some(i => i.hasOwnProperty('saved') && !i.saved)"
            >
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-sheet height="500">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"

          :type="type"
          @click:date="viewDay"

          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"

          :events="events"
          :event-color="getEventColor"
          :event-ripple="false"
          @change="getEvents"

          first-interval="7"
          last-interval="22"
          :weekdays="weekday"
        >
        <template v-slot:event="{ event, timed, eventSummary }">
          <div class="px-1 font-weight-medium">
            {{event ? event.name : 'unset'}}
            {{event.subject && '(' + event.subject.name + ')'}}
            {{event.subjects && '(' + event.subjects.map(subject => subject.name).join(', ') + ')'}}
          </div>
          <div class="v-event-draggable" style="position:relative;">
<!--            <component :is="{ render: eventSummary }"></component>-->
            <span v-if="timed" style="position: absolute; top: 0px; right: 4px;">
              <v-row no-gutters>
                <v-btn x-small text class="info mx-2" @click="showEvent(event)">Edit</v-btn>
                <v-btn x-small text class="error" @click="remove(event)">Clear</v-btn>
              </v-row>
            </span>
          </div>

          <div
            v-if="timed"
            class="v-event-drag-bottom"
            @mousedown.stop="extendBottom(event)"
          ></div>
        </template>
      </v-calendar>
      </v-sheet>

      <v-dialog v-model="selectedOpen" max-width="600">
        <v-card>
          <v-card-title>Lesson</v-card-title>
          <v-card-text>
            <v-text-field
              hide-details
              v-model="selectedEvent.name"
              label="Name"
            >
            </v-text-field>

            <v-autocomplete
              :items="school"
              v-model="selectedEvent.school"
              @change="loadType"
              label="Skola"
              item-text="name"
              item-value="id"
              return-object
            >
            </v-autocomplete>

            <v-autocomplete
              v-if="subjects.length"
              :items="subjects"
              v-model="selectedEvent.subjects"
              label="Type"
              item-text="name"
              item-value="id"
              return-object
              multiple
            ></v-autocomplete>

            <div class="py-4"> - You can use drag and drop to change time</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="selectedOpen = false" color="primary" text>Close</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </template>
  </layout-home>
</template>

<script>

import LayoutHome from '@/layouts/Home'
import axios from "../plugins/axios";
import config from '../config'
import moment from "moment"

export default {
  name: 'Home',
  components: {
    LayoutHome
  },
  data() {
    return {
      moment: moment,
      config: config,
      focus: '',
      type: 'month',
      weekday: [0, 1, 2, 3, 4, 5, 6],

      value: '',
      events: [],
      colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,

      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day'
      },

      selectedEvent: {},
      selectedOpen: false,
      school: [],
      subjects: []
    }
  },
  created() {
    axios.get('/time-2-slots').then(res => {
      this.events = res.data
    })

    axios.get('/school').then(res => {
      this.school = res.data
    })
  },
  methods: {
    loadType() {
      if(!this.selectedEvent.school) return
      axios.get('/school/' + this.selectedEvent.school.id).then(res => {
        this.subjects = res.data.subjects
      })
    },

    save() {
      let events = JSON.parse(JSON.stringify(this.events));

      this.events = this.events.map(event => {
        event.saved = true
        return event
      })

      events = events.filter(event => event.hasOwnProperty('saved') && !event.saved)

      events.forEach(event => {
        event.start = moment(event.start).format('YYYY-MM-DD HH:mm:ss')
        event.end = moment(event.end).format('YYYY-MM-DD HH:mm:ss')
        event.subjects = event.subjects
      })

      axios.post('/time-2-slots', {timeSlots: events})
    },
    showEvent (event) {
      this.selectedEvent = event
      this.selectedOpen = true
    },
    remove(event) {
      let index = this.events.findIndex(i => i === event)
      this.events.splice(index, 1)
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },

    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    startDrag ({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime (tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      } else {
        this.createStart = this.roundTime(mouse)
        this.createEvent = {
          name: `Cas ${this.events.length + 1}`,
          color: this.rndElement(this.colors),
          start: this.createStart,
          end: this.createStart,
          timed: true,
          saved: false,

          user_id: 1,
          subject: null,
          date: moment(this.createStart).format('YYYY-MM-DD')
        }

        this.events.push(this.createEvent)
        setTimeout(() => {
          this.showEvent(this.events[this.events.length - 1])
        }, 200)
      }
    },
    extendBottom (event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove (tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    endDrag () {
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag () {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime (time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    getEventColor (event) {
      const rgb = parseInt(event.color.substring(1), 16)
      const r = (rgb >> 16) & 0xFF
      const g = (rgb >> 8) & 0xFF
      const b = (rgb >> 0) & 0xFF

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
          ? `rgba(${r}, ${g}, ${b}, 0.7)`
          : event.color
    },
    getEvents ({ start, end }) {
      // const events = []
      //
      // const min = new Date(`${start.date}T00:00:00`).getTime()
      // const max = new Date(`${end.date}T23:59:59`).getTime()
      // const days = (max - min) / 86400000
      // const eventCount = this.rnd(days, days + 20)
      //
      // for (let i = 0; i < eventCount; i++) {
      //   const timed = this.rnd(0, 3) !== 0
      //   const firstTimestamp = this.rnd(min, max)
      //   const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000
      //   const start = firstTimestamp - (firstTimestamp % 900000)
      //   const end = start + secondTimestamp
      //
      //   events.push({
      //     name: this.rndElement(this.names),
      //     color: this.rndElement(this.colors),
      //     start,
      //     end,
      //     timed,
      //   })
      // }
      //
      // this.events = events
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    rndElement (arr) {
      return arr[this.rnd(0, arr.length - 1)]
    },
  }
}
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
