<template>
  <v-container
    class="fill-height login"
    fluid
  >
    <v-row justify="center">
      <v-col cols="12" lg="4">
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Reset Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                    outlined
                    dense
                    placeholder="Email">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  large
                  @click="$router.push({name: 'Home'})">
                  Back
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  large
                  :loading="loading"
                  :disabled="!valid"
                  color="primary"
                  @click="reset">
                  Rest
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar.status" top>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar.status = false"
        >
          Zatvoti
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import hasValidation from '../mixins/hasValidation'
import axios from '../plugins/axios'

export default {
  mixins: [hasValidation],
  data: () => ({
    valid: false,
    email: '',
    loading: false,
    snackbar: {
      status: false,
      text: ''
    }
  }),
  methods: {
    reset() {
      this.loading = true;
      axios.post('/user/forgot-password', {email: this.email})
        .then(res => {
          this.snackbar.text = 'Линк за ресетовање шифре Вам је послат на email'
          this.snackbar.status = true
          this.loading = false;
        }).catch(() => {
        /* this.snackbar.text = 'Email ne postoji u bazi'
        */
        this.snackbar.text = 'Линк за ресетовање шифре вам је послат на email'
        this.snackbar.status = true
        this.loading = false;
      })
    }
  }
}
</script>
