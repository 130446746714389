<template>
  <LayoutHome>
    <template v-slot:main>
      <v-container>
        <v-select
          :items="['All', 'Accept', 'Pending', 'Reject']"
          label="Select Status"
          v-model="eventStatus"
          solo
          @change="loadEvents"
          v-if="$store.state.isLogged"
        ></v-select>
        <v-row>
          <template v-for="event in events">
            <v-col cols="12">
              <event-card :event="event"></event-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </template>
  </LayoutHome>
</template>

<script>

import LayoutHome from '@/layouts/Home'
import axios from "../plugins/axios";
import eventCard from '../components/event-card'

export default {
  components: {
    LayoutHome,
    eventCard
  },
  data() {
    return {
      events: [],
      eventStatus: 'All'
    }
  },
  methods: {
    loadEvents() {
      axios.get('/events?status=' + this.eventStatus.toLowerCase()).then(res => {
        this.events = res.data
        this.$store.commit('SET_EVENTS', this.events)

        this.events.forEach(event => {

          event.users.forEach(user => {
            if(user.id === this.user.id) {
              event.isAttendee = true
              event.attendeeStatus = user.pivot.status
            }
          })
        })
      })
    }
  },
  mounted() {
    this.loadEvents()
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}

</script>
