export default {
  data: () => ({
    valid: false
  }),
  computed: {
    rules() {
      return {
        company: value => !value || value.length >= 2 || 'At least 2 characters',
        email: value => {
          const pattern = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
          return !value || pattern.test(value) || 'Please enter a valid email address.'
        },
        firstName: (value) => {
          const pattern = /[A-Z]{1}[a-z]+/;
          return !value || pattern.test(value) || 'Invalid first name.'
        },
        storeName: (value) => {
          const pattern = /^[a-z0-9][a-z0-9_-]+[a-z0-9]$/;
          return !value || pattern.test(value) || 'Invalid store url.'
        },
        fullName: (value) => {
          const pattern = /[A-Z]{1}[a-z]+\s[A-Za-z]+(\s?)([A-Za-z]?)/;
          return !value || pattern.test(value) || 'Invalid full name.'
        },
        hyperlink: value => {
          const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
          const mailto = value.startsWith('mailto:')
          return !value || mailto || pattern.test(value) || 'Invalid hyperlink.'
        },
        onlyString: value => !value || /^[a-zA-Z\s+]*$/.test(value) || 'Only characters allowed',
        order: value => value > 0 || 'Ordering must be higher then 0',
        password: value => value.length >= 8 || 'Password must be at least 8 characters',
        phone: value => {
          let pattern = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
          return !value || pattern.test(value) || 'Invalid phone number';
        },
        repeatPassword: (value, password) => {
          return !value || value === password || 'Passwords do not match'
        },
        required: value => !!value || 'Required.',
        twitter: value => value.match(/(^|[^'"])(https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+))/) || 'Invalid twitter url.',
        vimeo: value => value.indexOf('https://vimeo.com/') > -1 || 'Invalid vimeo link.',
        youtube: value => value.indexOf('youtube.com') > -1 && value.indexOf('v=') > -1 || 'Invalid youtube link.'
      }
    },
    rulesLocale() {
      return {
        en: {
          company: value => !value || value.length >= 2 || 'At least 2 characters',
          email: value => {
            const pattern = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
            return !value || pattern.test(value) || 'Please enter a valid email address.'
          },
          firstName: (value) => {
            const pattern = /[A-Z]{1}[a-z]+/;
            return !value || pattern.test(value) || 'Invalid first name.'
          },
          storeName: (value) => {
            const pattern = /^[a-z0-9][a-z0-9_-]+[a-z0-9]$/;
            return !value || pattern.test(value) || 'Invalid store url.'
          },
          fullName: (value) => {
            const pattern = /[A-Z]{1}[a-z]+\s[A-Za-z]+(\s?)([A-Za-z]?)/;
            return !value || pattern.test(value) || 'Invalid full name.'
          },
          hyperlink: value => {
            const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            const mailto = value.startsWith('mailto:')
            return !value || mailto || pattern.test(value) || 'Invalid hyperlink.'
          },
          onlyString: value => !value || /^[a-zA-Z\s+]*$/.test(value) || 'Only characters allowed',
          order: value => value > 0 || 'Ordering must be higher then 0',
          password: value => value.length >= 8 || 'Password must be at least 8 characters',
          phone: value => {
            let pattern = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
            return !value || pattern.test(value) || 'Invalid phone number';
          },
          repeatPassword: (value, password) => {
            return !value || value === password || 'Passwords do not match'
          },
          required: value => !!value || 'Required.',
          twitter: value => value.match(/(^|[^'"])(https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+))/) || 'Invalid twitter url.',
          vimeo: value => value.indexOf('https://vimeo.com/') > -1 || 'Invalid vimeo link.',
          youtube: value => value.indexOf('youtube.com') > -1 && value.indexOf('v=') > -1 || 'Invalid youtube link.'
        },
        de: {
          company: value => !value || value.length >= 2 || 'At least 2 characters',
          email: value => {
            const pattern = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
            return !value || pattern.test(value) || 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
          },
          firstName: (value) => {
            const pattern = /[A-Z]{1}[a-z]+/;
            return !value || pattern.test(value) || 'Invalid first name.'
          },
          storeName: (value) => {
            const pattern = /^[a-z0-9][a-z0-9_-]+[a-z0-9]$/;
            return !value || pattern.test(value) || 'Invalid store url.'
          },
          fullName: (value) => {
            const pattern = /[A-Z]{1}[a-z]+\s[A-Za-z]+(\s?)([A-Za-z]?)/;
            return !value || pattern.test(value) || 'Invalid full name.'
          },
          hyperlink: value => {
            const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            const mailto = value.startsWith('mailto:')
            return !value || mailto || pattern.test(value) || 'Invalid hyperlink.'
          },
          onlyString: value => !value || /^[a-zA-Z\s+]*$/.test(value) || 'Only characters allowed',
          order: value => value > 0 || 'Ordering must be higher then 0',
          password: value => value.length >= 8 || 'Password must be at least 8 characters',
          phone: value => {
            let pattern = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
            return !value || pattern.test(value) || 'Invalid phone number';
          },
          repeatPassword: (value, password) => {
            return !value || value === password || 'Passwords do not match'
          },
          required: value => !!value || 'Erforderlich.',
          twitter: value => value.match(/(^|[^'"])(https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+))/) || 'Invalid twitter url.',
          vimeo: value => value.indexOf('https://vimeo.com/') > -1 || 'Invalid vimeo link.',
          youtube: value => value.indexOf('youtube.com') > -1 && value.indexOf('v=') > -1 || 'Invalid youtube link.'
        }
      }
    },
  }
}
