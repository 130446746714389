<template>
  <v-row no-gutters>
    <v-col cols="12" class="py-5">
      <span class="primary--text text-h4 font-weight-bold pt-4" style="border-top: 4px solid #0A32E6">
        FAQ
      </span>
    </v-col>

    <v-expansion-panels accordion>
      <v-expansion-panel
        v-for="(item,i) in items"
        :key="i"
        style="border: 1px solid #fed08e; border-radius: 8px; padding: 15px" class="mb-4"
      >
        <v-expansion-panel-header hide-actions>
          <v-row no-gutters>
            <v-col cols="auto">
              <v-icon x-large color="#8d5cf6" class="my-n1">mdi-chevron-right</v-icon>
            </v-col>
            <v-col>
              <div class="text-h5 font-weight-bold">
                {{item.title}}
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="auto" style="visibility: hidden">
              <v-icon x-large color="#8d5cf6" class="my-n1">mdi-chevron-right</v-icon>
            </v-col>
            <v-col>
              <div>
                {{item.text}}
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
      items: [
        {
          title: 'Kako se Studdy Buddy razlikuje od tradicionalnih platformi za online učenje?',
          text: 'Studdy Buddy se razlikuje od tradicionalnih platformi za online učenje na nekoliko ključnih načina.\n' +
            '              Prije svega, nudimo personalizirano mentorstvo od strane stručnih mentora.\n' +
            '              Naši mentori su tu da vam pruže personalizovane povratne informacije i podršku i\n' +
            '              pomognu vam da ostanete motivisani i na pravom putu. Osim toga, naši unaprijed snimljeni časovi su\n' +
            '              interaktivni i zanimljivi, tako da se nikada nećete osjećati kao da samo pasivno gledate predavanje.- \n' +
            '            '
        },
        {
          title: 'Mogu li pristupiti svojim časovima kad god i gdje god želim?',
          text: 'Apsolutno! Jedna od ključnih karakteristika Studdy Budy platforme su naše fleksibilne opcije učenja.\n' +
            '              Svojim časovima možete pristupiti kad god i gdje god želite, tako da možete učiti po svom rasporedu.\n' +
            '              Bilo da radite na grupnom projektu ili imate samo nekoliko minuta viška, uvijek možete pronaći vremena\n' +
            '              za učenje uz Studdy Budy.'
        },
        {
          title: 'Koje vrste tema se obrađuju na časovima?',
          text: 'Nudimo širok spektar tema koje možete izabrati, tako da možete pronaći časove koji su u skladu sa vašim interesovanjima i ciljevima. Bilo da želite da poboljšate svoje navike u učenju ili steknete dublje razumevanje određene teme, mi smo za vas.\n'
        },
        {
          title: 'Koliko dugo ću imati pristup časovima i mentorima?',
          text: 'Dužina pristupa varira u zavisnosti od paketa koji odaberete. Nudimo i mjesečne i godišnje pakete, tako da možete odabrati opciju koja vam najviše odgovara. Čak i nakon što vaš paket istekne, i dalje ćete imati pristup svim informacijama i resursima koji su vam potrebni za nastavak studiranja i postizanje vaših akademskih ciljeva.'
        },
        {
          title: 'Da li je Studdy Budy pristupačan?',
          text: 'Da! Naši paketi su osmišljeni tako da budu pristupačni, tako da možete dobiti podršku i smjernice koje su vam potrebne za postizanje vaših akademskih ciljeva, a da pritom ne kvarite novac. A uz fleksibilne opcije plaćanja, možete pronaći paket koji najbolje odgovara vama i vašem budžetu.'
        }
      ]
    }
  }
}

</script>

<style>
.faq-desc{
  color: #737373;
}
</style>
