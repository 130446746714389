<template>
  <layout-home>
    <template v-slot:main>
      <v-data-table
        :headers="headers"
        :items="desserts"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Events</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>

            <v-dialog
              v-model="dialog"
              max-width="60%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  New Event
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>


                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        label="Event name"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <vue-editor label="Event description" v-model="editedItem.description"></vue-editor>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.price"
                        label="Price"
                        outlined
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="space-between" align="center" class="fill-height" no-gutters>
                    <div class="title">Speakers</div>
                    <v-btn @click="dialogSpeaker = true" text>Add Speaker</v-btn>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="ma-2">
                    <template v-for="(n, i) in editedItem.speakers">
                      <v-col cols="12" sm="4">
                        <v-card height="180px" class="pa-2" style="position:relative;">
                          <v-btn @click="editedItem.speakers.splice(i, 1)" icon style="position:absolute; top: 4px; right: 6px">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                          <v-row justify="center" align="center" style="height: 160px" no-gutters>
                            <div style="text-align: center">
                              <v-avatar size="72">
                                <v-img :src="`https://picsum.photos/500/300?image=${(i+1) * 5 + 10}`"></v-img>
                              </v-avatar>
                              <br>
                              <div class="py-2" style="text-align: center">
                                {{n.user.name}}
                              </div>
                              <div>
                                From: {{n.from}}<br>
                                To: {{n.to}}
                              </div>
                            </div>
                          </v-row>
                        </v-card>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                  >
                    Close
                  </v-btn>
                  <v-btn @click="save" color="primary" class="black--text" tile :disabled="!editedItem.speakers.length">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.description="{ item }">
          <div class="custom-description">{{item.description}}</div>
        </template>
        <template v-slot:item.state="{ item }">
          <v-switch v-model="item.state" hide-details></v-switch>
        </template>
        <template v-slot:item.speakers="{ item }">
          <template v-for="(slot, i) in item.speakers">
            {{moment(slot.from).format('D MMM YYYY')}} / {{moment(slot.from).format('HH:mm')}}-{{moment(slot.to).format('HH:mm')}} {{slot.user.name}}
            <br>
          </template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-btn
            outlined
            small
            class="ml-1"
            :to="{name: 'Meet-Id-Users', params: {id: item.id}}"
          >
            {{item.users_count}} Users
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>

      <v-dialog
        v-model="dialogSpeaker"
        max-width="80%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
            <v-spacer></v-spacer>
            <v-btn @click="dialogSpeaker = false" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-stepper v-model="e2" class="elevation-0">
              <v-stepper-header class="px-4 elevation-0">
                <v-icon step="1" >{{e2 == 1 ? 'mdi-cube' : 'mdi-cube-outline'}}</v-icon>
                <v-divider class="mx-1"></v-divider>
                <v-icon step="1">{{e2 == 2 ? 'mdi-cube' : 'mdi-cube-outline'}}</v-icon>
                <v-divider class="mx-1"></v-divider>
                <v-icon step="1">{{e2 == 3 ? 'mdi-cube' : 'mdi-cube-outline'}}</v-icon>
                <v-divider class="mx-1"></v-divider>
                <v-icon step="1">{{e2 == 4 ? 'mdi-cube' : 'mdi-cube-outline'}}</v-icon>
                <v-divider class="mx-1"></v-divider>
                <v-icon step="1">{{e2 == 5 ? 'mdi-cube' : 'mdi-cube-outline'}}</v-icon>
              </v-stepper-header>

              <v-stepper-items>

                <v-stepper-content step="1">
                  <div class="title">Odaberi predmet</div>
                  <v-list>
                    <v-list-item-group>
                      <template v-for="(item, index) in school">
                        <v-list-item :key="item.title" @click="selectedSchool = item, e2 = 2">
                          <template v-slot:default="{ active }">
                            <v-list-item-icon>
                              <v-icon>{{item.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title v-text="item.name"></v-list-item-title>
                              <v-list-item-subtitle
                                class="text--black"
                                v-text="item.name"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>

                        <v-divider
                          v-if="index < school.length - 1"
                          :key="index"
                        ></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <div class="title">Odaberi predmet</div>
                  <v-list v-if="selectedSchool">
                    <v-list-item-group>
                      <template v-for="(item, index) in selectedSchool.subjects">
                        <v-list-item :key="item.title" @click="loadSpeakers(item), filters.subject_id = item.id; e2 = 3">
                          <template v-slot:default="{ active }">
                            <v-list-item-icon>
                              <v-icon>{{item.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title v-text="item.name"></v-list-item-title>
                              <v-list-item-subtitle
                                class="text--black"
                                v-text="item.name"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>

                        <v-divider
                          v-if="index < selectedSchool.subjects.length - 1"
                          :key="index"
                        ></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                  <v-btn @click="e2 = 1" text class="mt-4">Back</v-btn>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <div class="title">Odaberi Trajanje Usluge</div>
                  <v-row class="ma-2">
                    <template v-for="n in [{time: 45, price: 10}, {time: 60, price: 12}, {time: 90, price: 15}]">
                      <v-col cols="12" sm="4">
                        <v-card height="160px" class="pa-2" style="position:relative;" @click="filters.minutes = n.time; e2 = 4">
                                <span style="position:absolute; top: 10px; right: 10px; color: #d1c133" class="font-weight-medium">
                                  <!-- {{n.price}}km -->
                                </span>
                          <v-row no-gutters justify="center" align="center" class="fill-height">
                            <div style="font-size: 32px; text-align: center">
                              <div>{{n.time}}</div>
                              <div style="font-size: 18px">Minuta</div>
                            </div>
                          </v-row>
                        </v-card>
                      </v-col>
                    </template>
                    <v-btn @click="e2 = 2" text class="mt-4">Back</v-btn>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="4">
                  <div class="title">Odaberite vašeg instruktora</div>
                  <v-row class="ma-2">
                    <template v-for="(n, i) in userSpeakers.users">
                      <v-col cols="12" sm="4">
                        <v-card height="180px" class="pa-2" @click="filters.user_id = n.id, e2 = 5">
                          <v-row justify="center" align="center" style="height: 160px" no-gutters>
                            <div style="text-align: center">
                              <v-avatar size="72">
                                <v-img :src="`https://picsum.photos/500/300?image=${(i+1) * 5 + 10}`"></v-img>
                              </v-avatar>
                              <br>
                              <div class="py-2" style="text-align: center">
                                {{n.name}}
                              </div>
                              <div v-if="editedItem.speakers.find(i => i.user_id === n.id)">
                                From: {{editedItem.speakers.find(i => i.user_id === n.id).from}}<br>
                                To: {{editedItem.speakers.find(i => i.user_id === n.id).to}}
                              </div>
                            </div>
                          </v-row>
                        </v-card>
                      </v-col>
                    </template>
                  </v-row>
                  <v-btn @click="e2 = 3" text class="mt-4">Back</v-btn>
                </v-stepper-content>

                <v-stepper-content step="5">
                  <div class="title">Odaberi datum i vreme</div>
                  <v-row class="ma-2">

                    <event-calendar
                      ref="cal"
                      v-if="e2 === 5"
                      :filters="filters"
                      @slots="slots = $event">
                    </event-calendar>

                    <v-btn @click="e2 = 4" text class="mt-4">Back</v-btn>
                    <v-btn
                      v-if="slots && slots.data.some(i => i.selected)"
                      text
                      @click="addSlotToEvent"
                      class="mt-4"
                    >
                      Add Slots
                    </v-btn>
                  </v-row>
                </v-stepper-content>

              </v-stepper-items>
            </v-stepper>


          </v-card-text>
        </v-card>
      </v-dialog>

    </template>
  </layout-home>
</template>

<script>

import LayoutHome from '@/layouts/Home'
import { VueEditor } from "vue2-editor";
import datetime from 'vuejs-datetimepicker';
import eventCalendar from '../components/event-calendar';

import axios from "../plugins/axios";
import moment from "moment";

export default {
  components: {
    LayoutHome,
    VueEditor,
    datetime,
    eventCalendar
  },
  data: () => ({
    moment: moment,

    speakers: [],
    search: null,
    isLoading: false,

    dialog: false,
    dialogSpeaker: false,
    dialogDelete: false,
    headers: [
      { text: 'ID', value: 'id' },
      {
        text: 'Naslov',
        align: 'start',
        value: 'name',
        width: '20%'
      },
      { text: 'Date\\Speakers', value: 'speakers', sortable: false },
      { text: 'Status', value: 'state' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      description: '',
      state: 'inactive',
      price: 0,
      speakers: []
    },
    defaultItem: {
      name: '',
      description: '',
      state: 'inactive',
      price: 0,
      speakers: []
    },
    defaultTimeslot: {
      title: 'Naziv',
      description: '',
      date: new Date(),
      duration: '00:45:00',
      state: 'inactive',
      status: 'close',
      room: '',
      users: []
    },
    school: [],
    selectedSchool: null,
    userSpeakers: [],
    selectedSpeaker: null,
    selectedSubject: null,
    filters: {
      minutes: 45,
      subject_id: 1,
      date: null,
      user_id: null
    },
    e1: 1,
    e2: 1,
    slots: null,
    selectedSlots: []
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Event' : 'Edit Event'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    search (val) {
      // Items have already been loaded
      //if (this.speakers.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      axios.get('/users?type=speaker&q=' + val)
        .then(res => {
          // if(this.speakers.length) this.speakers.concat(res.data)
          // else this.speakers = res.data
          if(this.speakers.length) {
            this.speakers = [...this.speakers, ...res.data]
          }
          else {
            this.speakers = res.data
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },

  created () {
    this.initialize()
    this.loadSchool()
  },

  methods: {

    addSlotToEvent() {
      let reservedSlot = this.slots.data.find(i => i.selected)

      let user_id = reservedSlot.user.id

      let index = this.editedItem.speakers.findIndex(i => i.user.id === user_id )

      if(index > -1) {
        //this.editedItem.speakers.splice(index, 1)
      }

      this.editedItem.speakers = this.editedItem.speakers.concat(reservedSlot)
      this.$refs.cal.resetSlot()
      this.e2 = 4
      this.dialogSpeaker = false
    },

    loadSpeakers(subject) {
      this.selectedSubject = subject
      axios.get('/subject/' + subject.id)
        .then(res => {
          this.userSpeakers = res.data
        })
    },

    loadSchool() {
      axios.get('/school')
        .then(res => {
          this.school = res.data
        })
    },

    loadUsers() {
      let ids = [];
       this.editedItem.timeslots.forEach(timeslot => {
         timeslot.users.forEach(user => {
           ids.push(user.id)
        })
      })

      axios.get('/users?ids=' + ids)
        .then(res => {
          this.speakers = res.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },

    removeTimeslot(timeslot, index) {
      if(timeslot.id) {
        axios.delete('/timeslots/' + timeslot.id).then(res => {
          this.editedItem.timeslots.splice(index, 1)

          this.$store.state.snackBar = {
            state: true,
            message: 'Success',
            color: 'success'
          }
        })
      }
      else {
        this.editedItem.timeslots.splice(index, 1)
      }
    },

    initialize () {

      axios.get('/events').then(res => {
        this.desserts = res.data
      })

    },

    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true

      //this.loadUsers()
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      let editedItem = this.editedItem
      let editedIndex = this.editedIndex
      if (this.editedIndex > -1) {
        let ids = this.editedItem.speakers.map(i => i.ids).flat(1)
        this.editedItem.timeslots = ids
        this.editedItem.subject_id = this.filters.subject_id
        axios.put('/events/' + this.editedItem.id, this.editedItem).then(res => {
          Object.assign(this.desserts[editedIndex], editedItem)
          this.$store.state.snackBar = {
            state: true,
            message: 'Success',
            color: 'success'
          }
        }).finally(() => {
          this.dialog = false
        })
      } else {
        let ids = this.editedItem.speakers.map(i => i.ids).flat(1)
        this.editedItem.timeslots = ids
        this.editedItem.subject_id = this.filters.subject_id
        axios.post('/events', this.editedItem).then(res => {
          //this.desserts.push(res.data)
          this.desserts.push(editedItem)
          this.editedItem.id = res.data.id
          this.$store.state.snackBar = {
            state: true,
            message: 'Success',
            color: 'success'
          }
        }).finally(() => {
          this.dialog = false
        })
      }

    },
  }
}

</script>

<style>
.custom-description {
  overflow: hidden;
  width:100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.v-input--switch {
  margin-top: 0px!important;
}
</style>
