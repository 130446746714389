<template>
  <LayoutHome>
    <template v-slot:main>
      <v-card class="mb-2">
        <v-card-text>
          <span class="font-weight-bold">{{event.name}}</span>
        </v-card-text>
      </v-card>
      <v-data-table
        v-if="event"
        :headers="headers"
        :items="event.users"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:item.name="{ item }">
          <div @click="dialog.user = item; dialog.state = true" style="text-decoration: underline; cursor: pointer">
            {{item.name}}
          </div>
        </template>
        <template v-slot:item.paid="{ item }">
          <v-edit-dialog
            :return-value.sync="item.pivot.paid"
            large
            persistent
            @save="updatePaid(item, item.pivot.paid)"
          >
            {{ item.pivot.paid }}
            <template v-slot:input>
              <v-text-field
                v-model="item.pivot.paid"
                label="Edit"
                single-line
                counter
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.note="{ item }">
          <v-edit-dialog
            :return-value.sync="item.pivot.note"
            large
            persistent
            @save="updateNote(item, item.pivot.note)"
          >
            {{ item.pivot.note }}
            <template v-slot:input>
              <v-textarea
                v-model="item.pivot.note"
                label="Edit"
                single-line
                counter
                autofocus
              ></v-textarea>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn-toggle
            v-model="item.pivot.status"
            mandatory
            active-class="info white--text"
            @change="updateState(item)"
          >
            <v-btn value="pending" :color="item.pivot.status === 'pending' && 'info'">
              <v-icon>mdi-clock-time-eleven-outline</v-icon>
              pending
            </v-btn>
            <v-btn value="accept" :color="item.pivot.status === 'accept' && 'success'">
              <v-icon>mdi-check</v-icon>
              accept
            </v-btn>
            <v-btn value="reject" :color="item.pivot.status === 'reject' && 'error'">
              <v-icon>mdi-close</v-icon>
              decline
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-data-table>


      <v-dialog
        v-model="dialog.state"
        max-width="80%"
      >
        <v-card>
          <v-card-title class="text-h5">
            User details
          </v-card-title>

          <v-card-text>
            <template v-for="(field, index) in dialog.user">
              <div v-if="index != 'pivot'">
                <span class="font-weight-bold">{{index}}</span> : <span v-html="field"></span>
              </div>
            </template>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="dialog.state = false; dialog.user = null"
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </template>
  </LayoutHome>
</template>

<script>

import LayoutHome from '@/layouts/Home'
import axios from "../../../plugins/axios"

export default {
  components: {
    LayoutHome
  },
  data () {
    return {
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id'
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {text: 'Paid', value: 'paid', align: 'end', sortable: true},
        {text: 'Note', value: 'note', align: 'end', sortable: true},
        {text: 'Status', value: 'action', align: 'center', sortable: true}
      ],
      loaded: false,
      event: null,
      dialog: {
        state: false,
        user: null
      }
    }
  },
  mounted() {
    axios.get('/events/' + this.$route.params.id)
      .then(res => {
        this.event = res.data
      })
      .finally(() => {
        this.loaded = true
      })
  },
  methods: {
    updateState(item) {
      console.log('aaa')
      if(!this.loaded) return
      axios.put('/users/events/' + this.$route.params.id + '/status', item)
        .then(res => {

        })
    },
    updatePaid(user, item) {
      if(!this.loaded) return
      axios.put('/users/events/' + this.$route.params.id + '/paid', {id: user.id, paid: item})
        .then(res => {

        })
    },
    updateNote(user, item) {
      if(!this.loaded) return
      axios.put('/users/events/' + this.$route.params.id + '/note', {id: user.id, note: item})
        .then(res => {

        })
    }
  }
}
</script>
