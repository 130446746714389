<template>
  <layout-home>
    <template v-slot:main>
      <div id="home">
        <img src="../assets/poz-1.png" style="position:absolute;"/>
        <v-container style="margin-top: 90px; margin-bottom: 90px">
        <v-row no-gutters>
          <v-col cols="12" sm="6" align="start">
            <img src="../assets/teta.png" style="position: relative; z-index: 1;"/>
          </v-col>
          <v-col cols="12" sm="5" class="ml-auto">
            <v-row justify="center" align="center" class="fill-height" no-gutters>
              <v-col cols="12" class="py-0 fill-height">
                <v-row no-gutter align="center" class="fill-height">
                <h1 class="heading mb-3 font-weight-bold primary--text">
                  OSTVARI SVOJE CILJEVE UZ PERSONALIZOVANE PAKETE MENTORSTVA ILI SNIMLJENE ČASOVE
                </h1>
                <p class="title font-weight-light" style="line-height: 1.7 !important;">
                  Uz stručnu podršku naših mentora nema više muke sa lekcijama!
                  Pripremili smo ti podršku za najvažnije oblasti: matematika, hemija, fizika, jezici.
                </p>
<!--                @click="dialog = false"-->
                <v-btn
                  elevation="0"

                  color="primary"
                  class="white--text"
                  x-large
                >
                  IZABERI PAKET
                </v-btn>
                <div class="title font-weight-medium primary--text" style="line-height: 1.7 !important;">
                  I POČNI DA NAPREDUJEŠ VEĆ DANAS!
                </div>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      </div>
      <v-row no-gutters>
      <v-col cols="12" class="secondary">
        <v-container style="margin-top: 80px; margin-bottom: 80px">
          <about id="about"></about>
        </v-container>
      </v-col>
      <v-col cols="12" class="white">
       <v-container style="margin-top: 80px; margin-bottom: 80px">
         <characteristics id="characteristics"></characteristics>
       </v-container>
      </v-col>
      <v-col cols="12" class="secondary">
       <v-container style="margin-top: 80px; margin-bottom: 80px">
        <packets id="packets"></packets>
       </v-container>
      </v-col>
      <v-col cols="12" class="white">
       <v-container style="margin-top: 80px; margin-bottom: 80px">
         <faq></faq>
       </v-container>
      </v-col>
      </v-row>

      <v-dialog
        v-model="dialog"
        max-width="50%"
      >
        <v-stepper v-model="e1">
          <v-stepper-header class="px-4">
            <v-icon step="1">{{e1 == 1 ? 'mdi-cube' : 'mdi-cube-outline'}}</v-icon>
            <v-divider class="mx-1"></v-divider>
            <v-icon step="1" >{{e1 == 2 ? 'mdi-cube' : 'mdi-cube-outline'}}</v-icon>
            <v-divider class="mx-1"></v-divider>
            <v-icon step="1">{{e1 == 3 ? 'mdi-cube' : 'mdi-cube-outline'}}</v-icon>
            <v-divider class="mx-1"></v-divider>
            <v-icon step="1">{{e1 == 4 ? 'mdi-cube' : 'mdi-cube-outline'}}</v-icon>
            <v-divider class="mx-1"></v-divider>
            <v-icon step="1">{{e1 == 5 ? 'mdi-cube' : 'mdi-cube-outline'}}</v-icon>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="title">Odaberi predmet</div>
              <v-list>
                <v-list-item-group>
                  <template v-for="(item, index) in items">
                    <v-list-item :key="item.title" @click="e1 = 2">
                      <template v-slot:default="{ active }">
                        <v-list-item-icon>
                          <v-icon>{{item.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.title"></v-list-item-title>
                          <v-list-item-subtitle
                            class="text--black"
                            v-text="item.headline"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < items.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
              <v-btn @click="dialog = false" text>Close</v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="title">Odaberi predmet</div>
              <v-list>
                <v-list-item-group>
                  <template v-for="(item, index) in items">
                    <v-list-item :key="item.title" @click="filters.subject_id = index%2 ? 1 : 2; e1 = 3">
                      <template v-slot:default="{ active }">
                        <v-list-item-icon>
                          <v-icon>{{item.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.title"></v-list-item-title>
                          <v-list-item-subtitle
                            class="text--black"
                            v-text="item.headline"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < items.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
              <v-btn @click="e1 = 1" text>Back</v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <div class="title">Odaberi Trajanje Usluge</div>
              <v-row class="ma-2">
                <template v-for="n in [{time: 45, price: 10}, {time: 60, price: 12}, {time: 90, price: 15}]">
                  <v-col cols="12" sm="4">
                    <v-card height="160px" class="pa-2" style="position:relative;" @click="filters.minutes = n.time; e1 = 4">
                      <span style="position:absolute; top: 10px; right: 10px; color: #d1c133" class="font-weight-medium">
                        {{n.price}}km
                      </span>
                      <v-row no-gutters justify="center" align="center" class="fill-height">
                        <div style="font-size: 32px; text-align: center">
                          <div>{{n.time}}</div>
                          <div style="font-size: 18px">Minuta</div>
                        </div>
                      </v-row>
                    </v-card>
                  </v-col>
                </template>
                <v-btn @click="e1 = 2" text>Back</v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="4">
              <div class="title">Odaberite vašeg instruktora</div>
              <v-row class="ma-2">
                <template v-for="(n, i) in [{name: 'Karlo', img: 'https://cdn.vuetifyjs.com/images/john.jpg'}, {name: 'Luka', img: 'https://cdn.vuetifyjs.com/images/john.jpg'}, {name: 'Milos', img: 'https://cdn.vuetifyjs.com/images/john.jpg'}]">
                  <v-col cols="12" sm="4">
                    <v-card height="160px" class="pa-2" @click="e1 = 5">
                      <v-row justify="center" align="center" style="height: 160px">
                        <div>
                          <v-avatar size="72">
                            <v-img :src="`https://picsum.photos/500/300?image=${(i+1) * 5 + 10}`"></v-img>
                          </v-avatar>
                          <br>
                          <div class="py-2" style="text-align: center">
                            {{n.name}}
                          </div>
                        </div>
                      </v-row>
                    </v-card>
                  </v-col>
                </template>
                <v-btn @click="e1 = 2" text>Back</v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="5">
              <div class="title">Odaberi datum i vreme</div>
              <v-row class="ma-2">

                <event-calendar
                  ref="cal"
                  v-if="e1 === 5"
                  :filters="filters"
                  @slots="slots = $event">
                </event-calendar>

                <v-btn @click="e1 = 4" text>Back</v-btn>
                <v-btn v-if="slots" @click="e1 = 6" text :disabled="slots.data.every(i => !i.selected)">Next</v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-dialog>
    </template>
  </layout-home>
</template>

<script>
// @ is an alias to /src
import LayoutHome from '@/layouts/Home'
import axios from "../plugins/axios";
import moment from "moment";
import eventCalendar from '../components/event-calendar';
import about from '../components/about';
import characteristics from '../components/characteristics';
import packets from "../components/packets";
import faq from "../components/faq";

export default {
  name: 'Home',
  components: {
    LayoutHome,
    eventCalendar,
    about,
    characteristics,
    packets,
    faq
  },
  data: () => ({
    moment: moment,
    selected: [2],
    events: [],
    dialog: false,
    e1: 1,
    items: [
      {
        headline: 'Osnovna Skola',
        title: 'Osnovna Skola',
        icon: 'mdi-school-outline'
      },
      {
        headline: 'Srednja Skola',
        title: 'Srednja Skola',
        icon: 'mdi-account-school-outline'
      },
      {
        headline: 'Fakultet',
        title: 'Fakultet',
        icon: 'mdi-atom'
      },
      {
        headline: 'Birthday gift',
        title: 'Drzavna Matura',
        icon: 'mdi-file-outline'
      }
    ],

    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events2: [],
    filters: {
      minutes: 0,
      subject_id: 1,
      date: null
    },
    slots: null
  }),
  created() {
    axios.get('/events?limit=4&orderBy=DESC').then(res => {
      this.events = res.data
    })
  }
}
</script>

<style>
.slots {
  height: 30px;
  width: 15px;
  margin-left: 2px;
  margin-right: 2px;
  background: #8fe286;
  position: relative;
}
.slots:hover {
  background: #04BB02;
}

.slots2 {
  height: 30px;
  width: 15px;
  margin-left: 2px;
  margin-right: 2px;
  background: #e6e9ef;
  position: relative;
}
.slots2:hover {
  background: #E42806;
}

.slots3 {
  height: 35px;
  width: 14px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: -2px;
  background: #2196f3;
  position: relative;
}

</style>
