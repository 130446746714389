<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="primary"
      flat
    >
      <v-container class="py-0 fill-height">
        <img src="../assets/logo-study.png" height="56px">
        <div class="title white--text px-2">STUDY BUDDY</div>

        <v-spacer></v-spacer>

<!--        route.name !== link-->
        <v-btn
          v-for="link in links"
          :key="link.text"
          class="mx-2"
          @click="goTo(link)"
          text
          dark
        >
          {{ link.text }}
        </v-btn>
        <v-menu
          v-if="$store.state.isLogged"
          bottom
          min-width="200px"
          rounded
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              x-large
              v-on="on"
            >
              <v-avatar
                color="primary"
              >
                <img
                  :src="config.storage + '/data/users/' + user.id + '/medium/' + user.img"
                  alt="John"
                  v-if="$store.state.user.img"
                >
                <span
                  v-else
                  class="white--text text-h5"
                >
                  {{$store.state.user.name.split(" ").map((n)=>n[0]).join("")}}
                </span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar
                  color="brown"
                >
                  <span class="white--text text-h5">ND</span>
                </v-avatar>
                <h3>{{$store.state.user.name}}</h3>
                <p class="text-caption mt-1">
                  {{$store.state.user.email}}
                </p>
                <h4 class="mt-1">
                  -- {{$store.state.user.type.toUpperCase()}} --
                </h4>
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  :to="{name: 'Account'}"
                >
                  Account
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="logout"
                >
                  Logout
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
        <v-dialog
          v-else
          v-model="$store.state.dialog"
          width="400"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary"
              v-bind="attrs"
              v-on="on"
              class="black--text"
            >
              PRIDRUŽI SE
              <v-icon small>mdi-arrow-right</v-icon>
            </v-btn>
          </template>

          <v-card elevation="0">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="$store.state.dialog = false, dialogType = 'login'"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
            <login v-if="dialogType === 'login'" @register="dialogType = 'register'"></login>
            <register v-else></register>
          </v-card>
        </v-dialog>
      </v-container>
    </v-app-bar>

    <v-main class="white pb-4">

        <v-row class="fill-height">
          <v-col>
            <slot name="main"></slot>
          </v-col>
        </v-row>

    </v-main>

    <v-footer
      padless
    >
      <v-row no-gutters>
        <v-col cols="12" class="secondary">
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" sm="5" align="start">
                <img src="../assets/study_buddy.png" height="120px">
              </v-col>
              <v-col cols="12" sm="4" align="start" class="font-weight-bold">
                <div class="title py-2">Linkovi</div>
                <div class="link-desc py-1">Home</div>
                <div class="link-desc py-1">O nama</div>
                <div class="link-desc py-1">Ključne karakteristike</div>
                <div class="link-desc py-1">Paketi</div>
              </v-col>
              <v-col cols="12" sm="3" align="start" class="font-weight-bold">
                <div class="title py-2">Kontakt</div>
                <div class="link-desc py-1">
                  <v-icon color="#8D5CF6">mdi-phone</v-icon>
                  (480) 555-0103
                </div>
                <div class="link-desc py-1">
                  <v-icon color="#8D5CF6">mdi-map-marker-outline</v-icon>
                  Adresa, primjer
                </div>
                <div class="link-desc py-1">
                  <v-icon color="#8D5CF6">mdi-email-outline</v-icon>
                  email@example.com
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" class="primary">
          <v-container>
            <v-card
              class="flex primary"
              flat
              tile
            >
              <v-card-title>
                <strong class="subtitle-1 secondary--text font-weight-medium">All Right Reserved</strong>

                <v-spacer></v-spacer>

                <v-btn
                  v-for="icon in icons"
                  :key="icon"
                  class="mx-4"
                  color="secondary"
                  icon
                >
                  <v-icon size="24px">
                    {{ icon }}
                  </v-icon>
                </v-btn>
              </v-card-title>

            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-footer>


    <v-snackbar
      v-model="$store.state.snackBar.state"
      :color="$store.state.snackBar.color"
    >
      {{ $store.state.snackBar.message }}
    </v-snackbar>
  </v-app>
</template>

<script>

import config from '../config'
import login from '../views/Login'
import register from '../views/Register'

export default {
  components: {login, register},
  data: () => ({
    config: config,
    links: [{text: 'Home', scrool: 'home'}, {text: 'O nama', scrool: 'about'}, {text: 'Ključne karakteristike', scrool: 'characteristics'}, {text: 'Paketi', scrool: 'projects'}, {text: 'Kontakt', scrool: 'contact'}],
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
    dialogType: 'login'
  }),
  created() {
    this.$store.dispatch('GET_PROFILE')

    // if(!this.$store.state.isLogged && this.$route.path.includes('/dashboard')){
    //   this.$router.replace({name: 'index'})
    // }
  },
  methods: {
    goTo(link) {
      if(link.scrool) {
        this.$vuetify.goTo('#' + link.scrool, [])
      }
      else {
        this.$router.push({name: link})
      }
    },
    logout() {
      localStorage.removeItem('meet-token');
      this.$store.commit('REMOVE_USER')
      this.$router.push({name: 'Home'})
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  watch: {
    user() {
      if(this.$store.state.isLogged) {
        switch (this.user.type) {
          case 'attendee': this.links = [{text: 'Home'}, {text:'Events'}, {text:'Speakers'}]
            break
          case 'speaker': this.links = [{text:'Home'}, {text:'Events'}, {text:'Speakers'}, {text:'Slots'}]
            break
          case 'admin': this.links = [{text:'Home'}, {text:'Admin'}, {text:'Users'}, {text:'Speakers'}, {text:'Events'}]
            break
        }
      }
    }
  }
}
</script>

<style scoped>
.theme--light.v-btn--active::before {
  opacity: 0!important;
}
.link-desc {
 color: #737373;
}
</style>
