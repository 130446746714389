<template>
  <layout-home>
    <template v-slot:main>
      <v-row no-gutters style="height: 100%">
        <v-col cols="auto" style="background: white">
          <v-autocomplete
            :items="$store.state.events"
            item-text="name"
            solo
            label="Select Event"
            v-model="$store.state.event"
            return-object
          ></v-autocomplete>

          <v-list
            v-if="selectedEvent"
            shaped
          >
            <v-list-item
              v-for="attendee in selectedEvent.attendees"
              :key="attendee"
              link
            >
              <v-list-item-content>
                <v-list-item-title>{{ attendee }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <v-row v-if="!selectedEvent" justify="center" align="center" class="fill-height title">
            Please Select Event
          </v-row>
          <div v-else id="meet-container" style="height: 100%"></div>
        </v-col>
      </v-row>

<!--      <div id="meet"  style="display: flex; justify-content: center; height: 100%"></div>-->
<!--      <div v-show="!loading" style="display: flex; justify-content: center; align-items: center">-->
<!--        <div class="loader"></div>-->
<!--      </div>-->
    </template>
  </layout-home>
</template>

<script>

import LayoutHome from '@/layouts/Home'

export default {
  components: {
    LayoutHome
  },
  data() {
    return {
      loading: false,
      isOn: true,
      api: null,
      events: [
        'Event One',
        'Event Two',
        'Event Three',
        'Event Fourth',
      ]
    }
  },
  methods: {
    loadRoom() {
      const element = document.getElementById("meet");
      if(element) element.remove();

      const rs = document.createElement('div')
      rs.setAttribute("id", "meet");
      rs.setAttribute("style", "display: flex; justify-content: center; height: 100%");
      document.getElementById('meet-container').appendChild(rs)

      const domain = 'meet.jit.si';
      const options = {
        roomName: this.selectedEvent.name,
        width: '100%',
        height: '100%',
        parentNode: document.querySelector('#meet'),
        lang: 'en',

        userInfo: {
          email: 'email@jitsiexamplemail.com',
          displayName: 'John Doe'
        }
      };
      this.api = new JitsiMeetExternalAPI(domain, options);
      this.loading = true
    }
  },
  computed: {
    selectedEvent() {
      return this.$store.state.event
    }
  },
  mounted() {
    let rs = document.createElement('script')
    rs.setAttribute('src', 'https://meet.jit.si/external_api.js')
    document.head.appendChild(rs)

    setTimeout(() => {
      if(this.selectedEvent) this.loadRoom()
    }, 1000)
  },
  watch: {
    selectedEvent() {
      this.isOn = false
      setTimeout(() => {
        this.loadRoom()
      }, 100)
    }
  }
}

</script>
