<template>
  <layout-home>
    <template v-slot:main>
      <template v-for="user in speakers">
        <v-card>
          <v-card-text>
            <v-row no-gutters justify="center" align="center" class="fill-height">
              <v-col cols="auto">
                <v-avatar
                  color="primary"
                  class="mx-1"
                >
                  <img
                    :src="config.storage + '/data/users/' + user.id + '/medium/' + user.img"
                    alt="John"
                    v-if="user.img"
                  >
                  <span
                    v-else
                    class="white--text text-h5"
                  >
                  {{user.name.split(" ").map((n)=>n[0]).join("")}}
                </span>
                </v-avatar>
              </v-col>
              <v-col>
                <div class="title px-2">{{user.name}}</div>
              </v-col>
            </v-row>
            <div v-html="user.description" class="py-4 px-2"></div>
          </v-card-text>
        </v-card>
      </template>
    </template>
  </layout-home>
</template>

<script>

import LayoutHome from '@/layouts/Home'
import axios from "../plugins/axios";
import config from '../config'

export default {
  name: 'Home',
  components: {
    LayoutHome
  },
  data() {
    return {
      config: config,
      speakers: []
    }
  },
  created () {
    axios.get('/users?type=speaker').then(res => {
      this.speakers = res.data
    })
  },
}

</script>
