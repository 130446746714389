<template>
  <layout-home>
    <template v-slot:main>
      <v-row no-gutters style="height: 100%">
        <v-col cols="auto" style="background: white">

        </v-col>
        <v-col>
          <div id="meet" style="height: 100%">
            <v-row v-if="!isOn" justify="center" align="center" class="fill-height ">
              <flip-countdown v-if="currentTimeslot" :deadline="moment(currentTimeslot[0].from).format('YYYY-MM-DD HH:mm:ss')" @timeElapsed="loadRoom"></flip-countdown>
              <v-alert color="yellow" icon="mdi-information-outline">
                <span class="black--text">{{message}}</span>
              </v-alert>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </template>
  </layout-home>
</template>

<script>

import LayoutHome from '@/layouts/Home'
import axios from "../../../../plugins/axios";
import moment from 'moment'
import FlipCountdown from 'vue2-flip-countdown'

export default {
  components: {
    LayoutHome,
    FlipCountdown
  },
  data() {
    return {
      moment: moment,
      loading: false,
      isOn: true,
      message: '',
      api: null,
      selectedEvent: null,
      currentTimeslot: null
    }
  },
  methods: {
    loadRoom() {
      this.currentTimeslot = this.selectedEvent.durations2.filter(timeslot => {
         if(timeslot.type === 'conference' && moment(timeslot.from).format('YYYY-MM-DD') === this.$route.params.timeslotId) {
           return timeslot
         }
      })
      if(
        !this.currentTimeslot
        ||
        moment(this.currentTimeslot[this.currentTimeslot.length - 1].to).isBefore(moment())
      ) {
        this.message = 'Event is finished'
        this.isOn = false
        return
      }

      if(
        !this.currentTimeslot
        ||
        moment(this.currentTimeslot[0].from).isAfter(moment())
      ) {
        this.message = 'Event not stated jet'
        this.isOn = false
        return
      }
      if(!this.selectedEvent.room_id) {
        this.message = 'Room name is not defined'
        this.isOn = false
        return
      }
      this.isOn = true
      const domain = 'meet.jit.si';
      const options = {
        roomName: this.selectedEvent.room_id,
        width: '100%',
        height: '100%',
        parentNode: document.querySelector('#meet'),
        lang: 'en',

        userInfo: {
          //email: 'email@jitsiexamplemail.com',
          displayName: this.user.name
        }
      };
      this.api = new JitsiMeetExternalAPI(domain, options);
      this.loading = true
    }
  },
  mounted() {
    let rs = document.createElement('script')
    rs.setAttribute('src', 'https://meet.jit.si/external_api.js')
    document.head.appendChild(rs)

    axios.get('/events/' + this.$route.params.id).then(res => {
      this.selectedEvent = res.data
      if(this.selectedEvent.timeslots.length || this.selectedEvent.users.length) {
        this.loadRoom()
      }
      else {
        this.message = 'You do not have permission'
        this.isOn = false
        return
      }
    })
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}

</script>
