<template>
  <v-row class="fill-height ma-0" justify="center" align="center">
    <v-card max-width="400px" width="100%" class="py-4">
      <v-row no-gutters justify="center" class="title primary--text font-weight-bold">
        Registruj se
      </v-row>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            prepend-inner-icon="mdi-account"
            label="Ime i Prezime"
            :rules="nameRules"
            filled
            v-model="name"
            required
          ></v-text-field>
          <v-text-field
            prepend-inner-icon="mdi-phone"
            label="Mobilni telefon"
            :rules="mobileRules"
            filled
            v-model="mobile"
            required
          ></v-text-field>
          <v-text-field
            prepend-inner-icon="mdi-email"
            label="Email"
            :rules="emailRules"
            filled
            v-model="email"
            required
          ></v-text-field>
          <v-text-field
            prepend-inner-icon="mdi-home-city"
            label="Grad"
            filled
            :rules="cityRules"
            v-model="city"
            required
          ></v-text-field>
          <v-text-field
            prepend-inner-icon="mdi-lock"
            label="Sifra"
            filled
            :rules="passwordRules"
            type="password"
            v-model="password"
            required
          ></v-text-field>
          <v-text-field
            prepend-inner-icon="mdi-lock"
            label="Ponovi Sifru"
            filled
            :rules="repeatPasswordRules"
            type="password"
            v-model="confirmPassword"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="mx-2">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="register"
          class="px-5 py-2"
        >
          Registruj se
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="snackbar.state"
      :timeout="10000"
      top
    >
      <template v-for="msg in snackbar.text">
        {{ msg.join() }}
      </template>

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbar.color"
          text
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-row>
</template>

<script>

import axios from "../plugins/axios";

export default {
  layout: 'blank',
  data() {
    return {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      mobile: '',
      city: '',
      valid: true,
      nameRules: [
        v => !!v || 'Ime i Prezime je obavezno',
        v => (v && v.length <= 45) || 'Ime i Prezime mora biti manje od 45 karaktera',
      ],
      emailRules: [
        v => !!v || 'E-mail je obavezan',
        v => /.+@.+\..+/.test(v) || 'E-mail mora biti ispravan',
      ],
      mobileRules: [
        v => !!v || 'Telefon je obavezan',
        v => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/.test(v) || 'Telefon mora biti ispravan',
      ],
      cityRules: [
        v => !!v || 'Grad je obavezno',
        v => (v && v.length <= 45) || 'Grad mora biti manji od 45 karaktera',
      ],
      passwordRules: [
        v => !!v || 'Sifra je obavezno',
        v => v.length >= 8 || 'Password must be at least 8 characters'
      ],
      repeatPasswordRules: [
        v => !!v || 'Sifra je obavezno',
        v => v === this.password || 'Sifre se ne poklapaju'
      ],
      snackbar: {
        state: false,
        text: '',
        color: ''
      }
    }
  },
  methods: {
    register() {
      if(this.$refs.form.validate()) {
        axios.post('/user/register', {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.confirmPassword,
          mobile: this.mobile,
          city: this.city
        }).then(res => {
          localStorage.setItem('meet-token', res.data.data.token)
          this.$router.push({name: 'Home'})
        }).catch(err => {
          this.snackbar.color = 'error'
          this.snackbar.text = err.response.data.errors
          this.snackbar.state = true
        })
      }
    }
  }
}
</script>
