<template>
  <v-card
    class="mx-auto"
    max-width="100%"
  >
    <v-card-text>
      <div>
        <v-chip
          :color="color(event.attendeeStatus)"
          v-if="event.isAttendee && event.attendeeStatus !== 'accept'"
          label
          class="mb-1"
          text-color="white"
          small
        >
          <v-icon left small>
            mdi-star
          </v-icon>
          {{event.attendeeStatus}}
        </v-chip>
      </div>
      <v-row class="text-h4 text--primary">
        <v-col cols="auto">
          {{event.name}}
        </v-col>
        <v-col align="end">
          <template v-for="(speaker, i) in event.speakers">
            <v-avatar
              color="primary"
              class="mx-1"
            >
              <img
                :src="config.storage + '/data/users/' + speaker.user.id + '/medium/' + speaker.user.img"
                alt="John"
                v-if="speaker.user.img"
              >
              <span
                v-else
                class="white--text text-h5"
              >
                {{speaker.user.name.split(" ").map((n)=>n[0]).join("")}}
              </span>
            </v-avatar>
          </template>
        </v-col>
      </v-row>
      <div>
        <v-row no-gutters align="start">
          <v-icon small class="pt-1 pr-2">mdi-calendar-clock</v-icon>
          <div>
            <template v-for="(duration, i) in event.durations2">
              <span :class="duration.type === 'pause' && 'red--text'">
                <v-icon :color="duration.type === 'pause' ? 'red' : 'blue'" small>{{duration.type === 'pause' ? 'mdi-pause-box' : 'mdi-play-box'}}</v-icon>
                {{moment(duration.from).format('D MMM YYYY HH:mm')}} - {{moment(duration.to).format('D MMM YYYY HH:mm')}}
              </span>
              <br>
            </template>
          </div>
        </v-row>
      </div>
      <p>
        <v-icon small>mdi-clock-time-one-outline</v-icon>
        {{durationTotal}} min
      </p>
      <div class="text--primary">

        <v-row class="text--primary">
          <v-col>
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="60%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                >Details</v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title>
                    Details
                  </v-card-title>
                  <v-card-text v-html="event.description"></v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      text
                      @click="dialog.value = false"
                    >Close</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <v-col cols="auto" align="end">
            <template v-if="event.isAttendee && event.attendeeStatus === 'accept'">
              <v-btn
                v-for="(duration, i) in event.durations2"
                v-if="duration.type === 'conference'"
                color="primary"
                v-bind="{outlined: moment(duration.from).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')}"
                class="mx-1"
                :to="{name: 'Meet-Id-Timeslot-Id', params: {id: event.id, timeslotId: moment(duration.from).format('YYYY-MM-DD')}}"
              >
                Open Day {{i+1}}
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <v-btn
              :dark="event.isAttendee ? false : true"
              outlined
              :color="event.isAttendee ? 'gray' : 'red lighten-1'"
              @click="$store.state.isLogged ? applyEvent(event) : $store.state.dialog = true"
            >
              {{event.isAttendee ? 'Dismiss' : 'Apply'}}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import axios from '../plugins/axios'
import moment from "moment"
import config from '../config'

export default {
  props: {
    event: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      config: config,
      moment: moment
    }
  },
  methods: {
    applyEvent(event) {
      axios.post('/users/events/' + event.id).then(res => {
        //event.isAttendee = true
        this.$set(event, 'isAttendee', true)
        this.$set(event, 'attendeeStatus', 'pending')

        this.$store.state.snackBar = {
          state: true,
          message: 'Success',
          color: 'success'
        }
      })
    },
    color(type) {
      switch(type) {
        case 'pending': return 'blue accent-1';
        break
        case 'reject': return 'red accent-1'
        break
      }
    }
  },
  computed: {
    durationTotal() {
      let duration = 0;
      this.event.durations2.forEach(item => {
        if(item.type === 'conference') duration += item.duration
      })
      return duration
    }
  }
}

</script>
