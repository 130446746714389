<template>
  <LayoutHome>
    <template v-slot:main>
      <v-row class="fill-height" v-if="$store.state.isLogged">
        <v-col cols="12" sm="3">
          <v-card class="fill-height">
            <v-card-text>
              <div v-if="$store.state.isLogged" class="mx-auto text-center">
                <v-avatar
                  color="brown"
                >
                  <span class="white--text text-h5">ND</span>
                </v-avatar>
                <h3>{{$store.state.user.name}}</h3>
                <p class="text-caption mt-1">
                  {{$store.state.user.email}}
                </p>
                <h4 class="mt-1">
                  <v-row justify="center" align="center" class="fill-height">
                    <v-icon color="warning" small class="pr-1">mdi-star</v-icon>
                      {{$store.state.user.type.toUpperCase()}}
                  </v-row>
                </h4>
                <v-divider class="my-3"></v-divider>
                <v-list flat>
                  <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in items"
                      :key="i"
                    >
                      <v-list-item-content :style="{backgroundColor: selectedItem === i ? '#c5e1a6' : 'white'}">
                        <v-list-item-title v-text="item.text" :style="{color: selectedItem === i ? 'white' : 'black'}"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="9">
          <v-stepper v-model="selectedItem" class="fill-height">
            <v-stepper-items>
              <v-stepper-content step="0" class="elevation-0">

                <div style="position:relative;width: 120px; margin-left: auto; margin-right: auto;">
                  <v-avatar
                    color="primary"
                    size="120"
                    class="mx-2"
                  >
                    <img
                      :src="user && user.img ? config.storage + '/data/users/' + user.id + '/medium/' + user.img : 'https://cdn.vuetifyjs.com/images/john.jpg'"
                    >
                  </v-avatar>
                  <v-btn fab color="white" small style="position: absolute;bottom: -10px;left: 100px;">
                    <label class="custom-file-upload">
                      <input type="file" @change="changeImage" id="upload">
                      <v-icon for="upload" color="#B94EE7" class="pointer">mdi-pencil</v-icon>
                    </label>
                  </v-btn>
                </div>

                <v-form class="pt-5">
                  <v-text-field label="Name" outlined v-model="$store.state.user.name"></v-text-field>
<!--                  <v-text-field label="Email" disabled outlined v-model="$store.state.user.email"></v-text-field>-->
                  <v-text-field label="Company" outlined v-model="$store.state.user.company"></v-text-field>
                  <v-text-field label="address" outlined v-model="$store.state.user.address"></v-text-field>
                  <v-text-field label="phone" outlined v-model="$store.state.user.phone"></v-text-field>
                  <v-text-field label="website" outlined v-model="$store.state.user.website"></v-text-field>
                  <v-text-field label="facebook" outlined v-model="$store.state.user.facebook"></v-text-field>
                  <v-text-field label="note" outlined v-model="$store.state.user.note"></v-text-field>
                  <div class="title">Description</div>
                  <vue-editor v-model="$store.state.user.description"></vue-editor>

                  <v-btn block color="primary" @click="save">Save</v-btn>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="1">
                <template v-for="event in events">
                  <event-card :event="event"></event-card>
                </template>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </template>
  </LayoutHome>
</template>

<script>

import LayoutHome from '@/layouts/Home'
import eventCard from '../components/event-card'
import axios from "../plugins/axios";
import config from '../config'
import { VueEditor } from "vue2-editor";

export default {
  components: {LayoutHome, eventCard, VueEditor},
  data() {
    return {
      config: config,
      selectedItem: 1,
      items: [
        { text: 'Profile', icon: 'mdi-account' },
        { text: 'Events', icon: 'mdi-calendar-check' }
      ],
      events: []
    }
  },
  methods: {
    changeImage() {

        let fileInput = document.getElementById('upload');
        let data = new FormData();
        data.append("image", fileInput.files[0]);

        axios.post('/users/'+this.user.id+'/images', data,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.$store.state.user.img = res.data

          this.$store.state.snackBar = {
            state: true,
            message: 'Success',
            color: 'success'
          }
        })

    },
    save() {
      axios.put('/user/' + this.$store.state.user.id, this.$store.state.user)
        .then(res => {
          this.$store.state.snackBar = {
            state: true,
            message: 'Success',
            color: 'success'
          }
        })
    }
  },
  mounted() {
    axios.get('/users/events').then(res => {
      this.events = res.data
      this.$store.commit('SET_EVENTS', this.events)

      this.events.forEach(event => {

        event.users.forEach(user => {
          if(user.id === this.user.id) {
            event.isAttendee = true
            event.attendeeStatus = user.pivot.status
            //this.$store.commit('ADD_EVENT', event)
          }
        })

        // event.timeslots.forEach(timeslot => {
        //   timeslot.users.forEach(user => {
        //     if(user.id === this.user.id) {
        //       event.isSpeaker = true
        //       this.$store.commit('ADD_EVENT', event)
        //     }
        //   })
        // })
      })
    })
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}

</script>

<style scoped>

input[type="file"] {
  display: none !important;
}

</style>
